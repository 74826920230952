
const config = {
    baseURL: window.location.origin,
    // imageOSS:"https://oss.qianniangy.net",
    // baseURL: "https://zhfwtest.daoben.tech",
}

if (process.env.NODE_ENV === 'development') {
    // config.baseURL = "https://manage.hnvalong.com/" //正式
    config.baseURL = "https://wl.daoben.tech/" //测试
}
export default config
