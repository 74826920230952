/**
 * @Author: yangzhenhua
 * @Date:   2023-03-25 23:45:11
 * @Last Modified by:   yangzhenhua
 * @Last Modified time: 2023-03-31 18:36:07
 */
import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router";
import {resetRouter} from "@/router";
import permission from './modules/permission'
import Cookies from "js-cookie";

Vue.use(Vuex)
const user_info = window.localStorage.getItem('user_info') || null
const token = Cookies.get('token') || null
export default new Vuex.Store({
    state: {
        app_first: true,  //app是不是第一次启动，启动页动画
        token: token,
        user_info: JSON.parse(user_info),
        fullscreenLoading: false,
        routers: [],  //路由（菜单）列表
        notice_list: [],  //我的备忘预警
        cachedViews: [],
        option: {
            domain_jqr: "",
            domain_zdp: "",
        },
    },
    getters: {
        getToken(state) {
            return state.token
        },
        getRouters(state) {
            return state.routers
        }
    },
    mutations: {
        //配置
        saveOption(state, provider) {
            state.option = provider
        },
        //添加缓存页面
        add_cached_view: (state, view) => {
            console.log("添加缓存页面", view)
            if (state.cachedViews.includes(view.name)) return
            // if (!view.meta.noCache) {
            // }
            state.cachedViews.push(view.name)

        },
        //删除某一个缓存页面
        del_cached_view: (state, view) => {
            const index = state.cachedViews.indexOf(view.name)
            index > -1 && state.cachedViews.splice(index, 1)
        },
        //删除全部缓存页面
        del_all_cached_view: state => {
            state.cachedViews = []
        },
        setAppFirst(state, provider) {
            state.app_first = provider
        },
        //设置我的备忘预警列表
        setNoticeList(state, provider) {
            state.notice_list = provider
        },
        setListType(state, provider) {
            state.list_type = Number(provider)
        },
        // 退出登录
        loginout(state) {
            
            state.token = null
            state.user_info = null
            state.list_type = 1
            Cookies.remove('token')
            window.localStorage.removeItem('user_info')
            window.localStorage.removeItem('city')
            router.push('/login')
            setTimeout(() => {
                resetRouter()
            }, 500)
        },
        setLoading(state, provider) {
            state.fullscreenLoading = provider.active
        },
        setUserInfo(state, provider) {
            const user = {...state.user_info, ...provider}
            window.localStorage.setItem('user_info', JSON.stringify(user))
            state.user_info = user;
        },
        setToken(state, provider) {
            Cookies.set('token', provider, {expires: 6 / 24})  //token6小时过期
            state.token = provider;
        },
        setMenuList(state, provider) {
            state.routers = provider
        }
    },
    actions: {},
    modules: {
        permission
    }
})
