<template>
  <div>
    <el-menu ref="el_menu" :default-active="String(default_active)" background-color="#242424" text-color="#888888"
      active-text-color="#fff" @select="onSelect">
      <template v-for="(item, index) in routers">
        <div class="sdsfax" v-if="item.id == sc_id">生产</div>
        <div class="sdsfax" v-if="item.id == 249">采购</div>
        <div class="sdsfax" v-if="item.id == ck_id">仓库</div>
        <div class="sdsfax" v-if="item.id == qt_id">其他</div>

        <!--只有一个菜单，没有子菜单-->
        <el-menu-item :index="String(index)" v-if="item.children.length === 0">
          <div class="item">
            <i-icon :name="item.meta.icon" color="#888888" size="18px"></i-icon>
            <span slot="title">{{ item.meta.title }}</span>
          </div>
        </el-menu-item>

        <!-- 子菜单只有一个的情况-->
        <el-menu-item :index="String(index)" v-else-if="item.children.length === 1">
          <div class="item">
            <i-icon :name="item.children[0].meta.icon" color="#888888" size="18px"></i-icon>
            <span slot="title">{{ item.children[0].meta.title }}</span>
          </div>
        </el-menu-item>

        <!--子菜单多个  --折叠-->
        <el-submenu :index="String(index)" v-else>
          <template slot="title">
            <div class="item">
              <i-icon :name="item.meta.icon" color="#888888" size="18px"></i-icon>
              <span>{{ item.meta.title }}</span>
            </div>
          </template>

          <!-- 子菜单-->
          <el-menu-item-group>
            <el-menu-item :index="index + '-' + c_index" v-for="(c_item, c_index) in item.children">
              <div class="item">
                <span slot="title">{{ c_item.meta.title }}</span>
              </div>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "sidebar",
  data() {
    return {
      old_router: [],
      routers: [],
      current_path: "",
      default_active: null,  //当前激活的index
      sc_id: '',
      ck_id: '',
      qt_id: ''
    }
  },
  watch: {
    $route(to, from) {
      // console.log(this.default_active,"sddddddddddddddddddddddddddddddddd",to.path)
    this.current_path = to.path

    this.getActive()   //获取要激活的index
    }
  },
  created() {
    console.log("eeee",this.$route)
    this.old_router = this.$store.state.routers.map(e => {

      e.open = false
      return e
    })

    const router_01 = this.old_router.filter(e => e.status === 'normal')
    // console.log(router_01,'this.old_routerthis.old_router')
    router_01.forEach(item => {
      item.children = item.children.filter(e => e.status === 'normal')
      if (!this.sc_id && (item.id == 233 || item.id == 243 || item.id == 245 || item.id == 247)) {
        this.sc_id = item.id
      }
      if (!this.ck_id && (item.id == 251||item.id == 278||item.id == 276)) {
        this.ck_id = item.id
      }
      if (!this.qt_id && (item.id == 5 || item.id == 239 || item.id == 241|| item.id == 287)) {
        this.qt_id = item.id
      }
    })

    this.routers = router_01
    this.current_path = this.$route.path

    this.getActive()   //获取要激活的index
  },

  methods: {


    //获取要激活的index
    getActive() {

      for (let i = 0; i < this.routers.length; i++) {
        console.log(this.routers[i].path,'this.routers[i].path')
        if (this.routers[i].path === this.current_path) {
          this.default_active = i
          break
        } else {
          this.old_router[i]['children'].forEach((e, index) => {
            if (e.path === this.current_path) {
              if (this.old_router[i]['children'].length == 1) {
                this.default_active = i
              } else {
                this.default_active = i + '-' + index
              }

            }
          })
        }
         
      }

    },
    //  菜单激活时
    onSelect(e) {
      let arr = e.split('-')
      let path = ""
      if (arr.length === 1) {
        path = this.routers[arr[0]].children[0]
      } else {
        path = this.routers[arr[0]]['children'][arr[1]]
      }

      this.$store.commit('del_all_cached_view')  //删除全部缓存页面


      if (path['is_href'] === 1) {
        const old = this.default_active;
        this.default_active = ""
        setTimeout(() => {
          this.default_active = old
        }, 0)

        window.open(`${path.path}`)
      } else {

        this.openWin(path['path'])
        this.default_active = e

      }

    }
  }

}
</script>

<style lang="scss" scoped>
.item {
  user-select: none;
}

.item .iconfont {
  margin-right: 6px;
}

.el-menu {
  border: none;
}

.el-menu-item {
  background-color: #242424 !important;
  font-size: 15px;
}

.sdsfax {
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.35);
  margin-bottom: 10px;
  margin-top: 5px;
}</style>
